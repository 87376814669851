.comingSoon {
  height: 100vh;
  width: auto;
  display: flex;
  flex-direction: column;
  user-select: none;
  background-color: black;
}
.comingSoonContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
}
.comingSoonText {
  background: linear-gradient(
    to right,
    #98f46e 0%,
    #5fe299 17%,
    #18d2a2 25%,
    #00aabc 30%,
    #6d3aef 53%,
    #ea6454 66%,
    #bc59bd 72%,
    #98f46e 100% /* #4579f4 100%, */
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  font-size: 7rem;
  font-weight: 900;
  animation: shine 12s linear infinite;
  background-size: 250% auto;
}
@keyframes shine {
  0% {
    background-position: 0% auto;
  }
  100% {
    background-position: 250% center;
  }
}
.comingSoonSubText {
  margin: 32px 0 !important;
  color: #b3b4b4 !important;
  line-height: 2.5px;
  width: 56% !important;
}
.comingSoonInpt {
  background-color: #292929;
  padding: 8px 10px;
  width: 64%;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  margin: 12px 0;
  margin-bottom: 0;
}
.comingSoonInpt:hover {
  transition: 0.3s ease-in-out;
  border: 1px solid #ffffff6d;
}
.comingSoonInpt input {
  background-color: transparent;
  border: none;
  outline: none;
  color: rgb(221, 221, 221);
  font-size: 1rem;
  width: 78.5%;
  margin-right: auto;
}
.comingSoonInpt input::placeholder {
  color: #4f4f4f;
  font-size: 1rem;
}

.comingSoonBtn {
  color: #292929 !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  background-color: #fff !important;
  margin: auto 0;
  margin-left: auto;
  transition: 0.3s ease-in-out;
}

.comingSoonBtn:hover {
  background-color: #ffffffdc !important;
  color: #292929 !important;
}
.socialIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.socialIcon {
  margin: 0 8px;
}
.socialIcon:hover {
  transform: scale(1.2);
  transition: 0.5s ease-in-out;
  color: #4f4f4f !important;
}

.landing {
  color: #fff;
  background-color: black;
}

.col1 {
  background-image: url("../../Assets//Images/back.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.508);
  display: flex;
  flex-direction: row;
}

/* .col1Body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  background-color: black;
} */
.col1BodyDetails {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 32%;
  position: absolute;
  top: 26%;
  left: 8%;
}
.tag {
  color: #fff;
  font-size: 13px;
  background-color: #e2dede23;
  backdrop-filter: blur(10px);
  padding: 6px 12px;
  width: fit-content;
  border-radius: 8px;
  margin-bottom: 2em;
}
.headerMainTitle {
  font-size: 46px !important;
  color: #fff !important;
  font-weight: bold !important;
  margin: 0.5em 0 !important;
}
.col1BodyConsultaion {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 28%;
  background-color: white;
  height: 72vh;
  position: absolute;
  overflow-y: scroll;
  top: 15%;
  padding: 2.5em 3.6em;
  right: 10%;
  backdrop-filter: blur(10px);
  box-shadow: rgba(224, 220, 220, 0.16) 0px 1px 4px;
  border: 1px solid transparent;
}
.col1BodyConsultaion::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.col1BodyConsultaion:hover {
  border: 1px solid #2dd06b;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
}

.landingFaq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 60%;
  margin-top: 5%;
}
.landingHeading {
  font-size: 36px !important;
  font-weight: 900 !important;
  color: #fff !important;
}
.landingTagline {
  font-size: 14.5px !important;
  color: #fff !important;
  font-weight: normal;
  margin: 1em 0 !important;
  opacity: 0.7;
  line-height: 1.6 !important;
  text-align: center;
  width: 80% !important;
  margin-bottom: 3em !important;
}

.landingWhy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
  margin-top: 5%;
}
.whyUs {
  width: 64%;
  margin: 2em auto;
}
.landingServices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
  margin-top: 5%;
}
.services {
  width: 100%;
  margin: 2em auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.serviceCardTop {
  width: 30%;
  margin: 1em auto;
  border-radius: 12px;
  border: 1px solid #8d8d8d51;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 480px;
}

.serviceCardTop:hover {
  border: 1px solid #2dd06b;
  transition: 0.3s ease-in-out;
}
.blobImage1 {
  margin-left: -120px;
  margin-top: -210px;
}
.blobImage2 {
  margin-right: -180px;
  margin-top: -240px;
}
.blobImage3 {
  margin-right: -180px;
  margin-top: -260px;
}
.blobImage4 {
  position: absolute;
  left: -100px;
  top: -100px;
  width: 400px;
}
.blobImage5 {
  width: 300px;
  margin-top: -120px;
}
.serviceCardTopContent {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 0 1em 1em 1em;
}
.serviceCardTopContentHeading {
  font-size: 32px !important;
  color: #fff !important;
  margin-top: -1em !important;
}
.serviceCardTopContentDescription {
  font-size: 14.5px !important;
  color: #fff !important;
  font-weight: normal;
  margin-top: 1em !important;
  opacity: 0.5;
  line-height: 1.6 !important;
}
.knowMore {
  margin: 2em auto;
  background-image: url("../../Assets/Images/servicesBack.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
  width: 74%;
  border-radius: 12px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.knowMoreHeading {
  font-size: 48px !important;
  color: #fff !important;
  font-weight: 900 !important;
  margin-bottom: 10px !important;
}
.knowMoreCard {
  background-color: rgba(0, 0, 0, 0.132);
  padding: 4px 8px;
  border-radius: 8px;
  margin: 0 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.knowMoreCardHeading {
  font-size: 14px !important;
  color: #fff !important;
  margin-left: 6px !important;
}
.hireTeamButton {
  margin-top: 2em !important;
  width: 20% !important;
  height: 50px !important;
  border-radius: 24px !important;
}
.landingSuccess {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
}
.landingSuccess_head {
  display: flex;
  flex-direction: row;
  padding: 2em 0;
}
.landingSuccess_head_left {
  width: 53%;
  height: 400px;
  margin-right: 1em;
  background-image: url("../../Assets/Images/MassCircles.svg");
  padding: 2.6em;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.landingSuccess_head_left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.landingSuccess_head_right {
  width: 47%;
  display: flex;
  flex-direction: column;
  padding: 1.2em 0 1.2em 1.2em;
}
@media screen and (max-width: 1200px) {
  .comingSoonBtn {
    font-size: 14px !important;
  }
  .comingSoonInpt {
    width: 50%;
  }
  .comingSoonInpt input {
    font-size: 1rem;
    width: 80%;
  }
  .comingSoonSubText {
    width: 56% !important;
  }
  .comingSoonContent {
    margin: auto;
  }
  .landingFaq {
    width: 90%;
  }
  .landingHeading {
    font-size: 28px !important;
  }
  .landingTagline {
    font-size: 12.6px !important;
    width: 90% !important;
  }
  .knowMore {
    height: auto;
  }
  .knowMoreHeading {
    font-size: 28px !important;
    text-align: center;
  }
  .knowMoreCard {
    margin: 5px 0;
  }
  .hireTeamButton {
    width: 50% !important;
    height: 42px !important;
  }
  .landingSuccess {
    width: 90%;
  }
  .landingSuccess_head {
    flex-direction: column;
  }
  .landingSuccess_head_left {
    width: auto;
    height: 300px;
    margin-right: 0;
    padding: 0 1em;
    background-image: none;
  }
  .landingSuccess_head_left img {
    height: 300px !important;
  }
  .landingSuccess_head_right {
    width: 100%;
    padding: 1.2em 0 1.2em 1.2em;
  }
  .services {
    flex-direction: column;
    margin: 1em 0;
  }
  .serviceCardTop {
    width: 100%;
  }
  .serviceCardTop:nth-child(4) {
    width: 100% !important;
  }
  .blobImage4 {
    margin-top: -80px;
  }
  .col1 {
    flex-direction: column !important;
  }

  .col1BodyDetails {
    top: 16% !important;
    width: 80% !important;
  }

  .col1BodyConsultaion {
    position: relative;
    margin-top: -140px;
    width: 80% !important;
    height: auto;
    right: 0;
    padding: 1.3em;
    box-shadow: rgba(204, 204, 204, 0.296) 0px 7px 29px 0px;
  }
  .headerMainTitle {
    font-size: 36px !important;
  }
  .landingWhy {
    width: 90%;
    margin-top: 12%;
  }
  .whyUs {
    width: 94%;
    margin: 1em auto;
  }
}

@media screen and (max-width: 768px) {
  .comingSoonText {
    font-size: 4.4rem;
    line-height: 78px;
  }
  .comingSoonInpt {
    width: 80%;
  }
  .comingSoonInpt input {
    font-size: 0.8rem;
    width: 68%;
  }
  .comingSoonSubText {
    width: 90% !important;
    font-size: 13.5px !important;
  }
  .comingSoonBtn {
    font-size: 12px !important;
  }
  .comingSoonContent {
    margin: auto;
  }
  .col1BodyConsultaion {
    margin-top: -90px !important;
  }
  .headerMainTitle {
    font-size: 28px !important;
  }
}
