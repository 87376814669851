.faqCard {
  margin-bottom: 10px !important;
  background-color: rgba(79, 79, 79, 0.33) !important;
  border: 1px solid rgba(217, 217, 217, 0.33) !important;
  padding: 1em !important;
  border-radius: 12px !important;
}
.faqQuestion {
  font-size: 19px !important;
  color: white !important;
  opacity: 0.9 !important;
  font-weight: normal;
}

.faqQuestion:hover {
  color: #2dd06b !important;
  opacity: 0.9 !important;
  transition: 0.3s ease-in-out !important;
}

.why-us-card {
  display: flex;
  flex-direction: row;
}
.why-us-icon{
  padding: 10px 6px;
  border: 1px solid rgba(91, 90, 90, 0.508);
  border-radius: 24px;
}
.why-us-timeline {
  height: 140px;
  width: 2px;
  background-color: rgba(91, 90, 90, 0.508);
}
.why-us-card-title {
  font-size: 22px !important;
  color: #fff !important;
  margin-bottom: 1em !important;
  margin-top: .3em  !important;
}

.why-us-card-description{
  font-size: 15px !important;
  color: #fff !important;
  opacity: 0.5 !important;
  line-height: 1.6 !important;
  margin-bottom: 1em !important;
}
@media screen and (max-width: 1200px) {
  .faqCard{
    padding: 6px !important;
  }
  .faqQuestion{
    font-size: 15.4px !important;
  }


}