@import url("https://fonts.googleapis.com/css2?family=Arimo&family=Source+Code+Pro:wght@300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
}

img {
  pointer-events: none;
  user-select: none !important;
}

a {
  text-decoration: none;
  color: black;
}

.MuiFormLabel-root-MuiInputLabel-root .Mui-focused {
  color: red !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.errorPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.errorPage img {
  width: auto;
  height: 100%;
}
@media screen and (max-width: 1000px) {
  .errorPage img {
    width: 100%;
    height: auto;
  }
}
