.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  height: 100vh;
}
.sidebar {
  background-color: #2e3a53;
  padding: 18px;
  color: #feffff;
  position: fixed;
  height: 92.5vh;
  border-radius: 8px;
  width: 12%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
}
.sidebar-header {
  position: absolute;
  top: 0;
}
.sidebar-menu {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
}
.sidebar-menu-item {
  display: flex;
  flex-direction: row;
  align-items: start;
  cursor: pointer;
  padding: 4px 8px;
  margin: 4px 0;
  margin-left: -6px;
  border-radius: 6px;
  background-color: transparent;
}
.sidebar-menu-item:hover {
  background-color: #3e51797d;
  transition: 0.25s ease-in-out;
}
.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #6d81ad;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
}
.sidebar-footer img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
.sidebar-footer-text {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
}
.main-content {
  background-color: #ffffff;
  padding: 20px;
}
.mainHeader {
  display: flex;
  flex-direction: row;
  padding: 10px 14px;
  border: 1px solid lightgray;
  margin-top: 6px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
}
.header {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  flex-direction: row;
  position: fixed;
  width: 100%;
  z-index: 100;
  padding: 10px;
  top: 0;
  /* background-color: rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(25px); */
}
.header-Search {
  margin: auto;
  background-color: #f3f5f9;
  width: 40%;
  margin-left: 1.4%;
  display: flex;
  flex-direction: row;
  padding: 4px;
  border-radius: 4px;
}

.header-menu-item:hover{
  opacity: 0.5 !important;
  transition: 0.3s ease-in-out !important;
}
.header-Search input {
  border: none;
  outline: none;
  padding: 6px 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #2e3a53;
  background-color: #f3f5f9;
  width: 92%;
  margin: auto;
}
.header-Search-icon {
  margin: auto;
  width: 8%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #2e3a53;
  cursor: pointer;
}

.header-button {
  background-color: rgba(103, 103, 103, 0.388) !important;
  backdrop-filter: blur(25px) !important;
  color: #fff;
  padding: 8px 15px;
  border-radius: 6px;
  text-align: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
}
.header-button:hover {
  opacity: 0.7;
  transition: 0.3s ease-in-out;
}
.header-button div {
  height: 5px;
  width: 5px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #f2ff58;
}

.footerBody {
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-top: 1px solid #d3cfcf37;
  border-bottom: 1px solid #d3cfcf37;
  width: 100%;
  padding: 3em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footerFoot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px;
  color: #feffff;
  padding-top: 28px;
  border-radius: 8px;
}
.footerBodyFlex {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: auto;
}
.footerFoot span {
  font-size: 13.5px;
  opacity: 0.7;
}
.footerFoot div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 15%;
}
.footerFoot div p {
  cursor: pointer;
  font-size: 13.5px;
  opacity: 0.7;
}
.footerFoot div span:hover {
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}
.footerTitle {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #feffff !important;
  margin-bottom: 1.2em !important;
}

.footerBusinessHrs {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d3cfcf37;
  margin-bottom: 12px;
  padding-bottom: 12px;
  justify-content: space-between;
}
.footerBusinessHrs:nth-child(4) {
  border-bottom: none;
}

.footerBusinessHrsTime,
.footerBusinessHrsTitle {
  font-size: 15px !important;
  font-weight: 500;
  color: #feffff !important;
  opacity: 0.6;
}

.footerSocialMedia {
  height: 32px;
  width: 32px;
  background-color: #31313158;
  border-radius: 8px;
  padding: 4px;
  margin-right: 0.8em;
  backdrop-filter: blur(20px);
  margin-top: 1.2em;
  margin-bottom: 1em;
}

.footerSocialMedia:hover {
  opacity: 0.7;
  transition: 0.3s ease-in-out;
  width: 30px;
  height: 30px;
}

.footerHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 42px;
  color: #feffff;
  padding-top: 28px;
  width: 90%;
  margin: auto;
  margin-top: 2%;
}
.footerHead_Btns {
  display: flex;
  flex-direction: row;
  margin: 2em;
  width: 31%;
  justify-content: space-between;
  align-items: center;
}
.footerHeadTitle {
  font-size: 40px !important;
  color: #fff !important;
  font-weight: bold !important;
  width: 60% !important;
  margin-top: 0.9em !important;
  margin-bottom: 1em !important;
  line-height: 1.5 !important;
}
.header-mobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .header {
    display: none;
  }
  .header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: transparent;
    color: #feffff;
    position: fixed;
    width: 96%;
    z-index: 100;
    top: 0;
    padding-top: 0.7em;
  }
  .footerHead {
    flex-direction: column;
    width: auto;
    padding: 10px 24px !important;
  }
  .footerHead_Btns {
    width: 100%;
    margin: 0;
    margin-bottom: 1.2em !important;
    justify-content: left;
    flex-wrap: wrap;
  }
  .footerHead_Btns button {
    margin-right: 1em;
  }
  .footerHeadTitle {
    margin-bottom: 0 !important;
  }
  .footerBody {
    flex-direction: column;
    padding: 1em;
    justify-content: left !important;
    align-items: left;
    width: auto;
  }
  .footerBodyFlex {
    width: 90%;
    margin: 0;
    margin-bottom: 1.2em;
  }
  .footerFoot {
    padding: 1em;
    justify-content: left;
    align-items: start;
    width: auto;
    flex-direction: column;
    height: auto;
    position: relative;
  }
  .footerFoot span {
    margin-bottom: 0.8em;
  }
  .footerLinks {
    display: flex;
    flex-direction: row !important;
  }
  .footerFoot div {
    width: 100%;
  }
  .footerFoot div p {
    width: auto;
    flex-direction: row;
  }
}
